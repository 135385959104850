import React from "react";

import Card from "./Card/Card.js";

import "./Cards.scss";

function Cards(props) {
  const { cards, height } = props;
  return (
    <>
      {height == "max" ? (
        <div className="cards-wrapper-max card-flex">
          {cards.map((card) => (
            <div key={card.index} className="card-wrapped">
              <Card
                title={card.title}
                body={card.body}
                height={card.height}
                route={card.route}
                icon={card.icon}
                newTab={card.newTab}
              ></Card>
            </div>
          ))}
        </div>
      ) : (
        <div className="cards-wrapper-min card-flex">
          {cards.map((card) => (
            <div key={card.index} className="card-wrapped">
              <Card
                title={card.title}
                body={card.body}
                height={card.height}
                route={card.route}
                icon={card.icon}
                newTab={card.newTab}
              ></Card>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Cards;
