import React from "react";

import {
  JUST_NUMBERS,
  PATTERN_NUMBERS,
  REAL_NUMBERS,
  JUST_CHARS,
  TRIM_STRING,
  CELLPHONE,
  EMAIL_VERIFICATION,
  EMAIL_PATTERN,
} from "../Regex";

/* CLASES CORRESPONDIENTES AL LLENADO DE FORMULARIOS PARA LA PANTALLA DE 
    PRESENTACIÓN DE DOCUMENTOS - MESA DE PARTES VIRTUAL */

/* INCLUYEN LA PANTALLA DE LOS 2 PASOS CORRESPONDIENTES AL FORMULARIO */

export const formInputs = [
  // Paso 1 : Datos de Remitente
  [
    {
      id: "doc",
      name: "documento",
      label: "Documento:",
      feedback: "Seleccione una opción.",
      required: true,
    },
    {
      id: "num",
      name: "numero",
      label: "Número:",
      pattern: PATTERN_NUMBERS,
      type: "number",
      required: true,
    },
    {
      id: "nom",
      name: "nombre",
      //label complejo -> en el mismo documento correspondiente
      type: "text",
      feedback: "Ingrese el nombre correspondiente.",
      required: true,
    },
    {
      id: "cel",
      name: "celular",
      label: "Celular:",
      type: "tel",
    },
    {
      id: "tel",
      name: "telefono",
      label: "Teléfono:",
      type: "tel",
    },
    {
      id: "anx",
      name: "anexo",
      placeholder: "Anexo:",
      type: "number",
    },
    {
      id: "email",
      name: "correo",
      label: <h4 className="text-primary">Correo Electrónico:</h4>,
      type: "email",
      placeholder: "nombre@ejemplo.com",
      // pattern: EMAIL_PATTERN,
      feedback: "Ingrese un correo válido para su posterior verificación.",
      required: true,
    },
    {
      id: "ver_mail",
      name: "verificacionMail",
      label: <h4 className="text-primary">Código de Verificación:</h4>,
      title: <p className="text-secondary">Solicitar código nuevamente</p>,
      placeholder: "Ingrese código",
      helper:
        "Verificado su correo electrónico se remitirá un código de verificación.",
      minLength: 6,
      maxLength: 6,
      // feedback: "Debe llenar este campo",
      required: true,
    },
    {
      id: "check",
      name: "autorizacion",
      label: (
        <h4 className="text-danger" style={{ textAlign: 'justify'}}>
          Autorizo al CARC PUCP para que envíe el cargo de presentación del
          documento a la dirección del correo electrónico señalada en este
          formulario.
        </h4>
      ),
      feedback: "Es necesario validar este campo.",
      required: true,
    },
  ],
  // Paso 2 : Datos de Documentos
  [
    {
      id: "expediente",
      type: "text",
      label: "Número de Expediente",
      feedback: "Ingrese el número de expediente correspondiente.",
      pattern: PATTERN_NUMBERS,
      override: "text-center",
      maxLength: 6,
      required: true,
    },
    {
      id: "asunto",
      name: "asunto",
      label: "Asunto:",
      feedback: "Ingrese un asunto",
      maxLength: 400,
      helper: "máximo 400 caracteres",
      height: "114px",
      required: true,
    },
  ],
];
