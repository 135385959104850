import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  deleteDocument,
  downloadDocument,
} from "../../../services/docSubmission";

import Modal from "../../Modals/Modals";
import Button from "../../Buttons/Buttons";

import "../List.scss";
import fileDownload from "js-file-download";
import prettyBytes from "pretty-bytes";

function FileItem(props) {
  const {
    name,
    size,
    value,
    openModal,
    setOpenModal,
    isDeleted,
    toDelete = false,
    toDownload = false,
    isButton = false,
    isUploaded = false,
    setError,
  } = props;

  const [selected, isSelected] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleModal = () => {
    isSelected(true);
    setOpenModal(true);
  };

  //Funcion para elimianr los archivos asociados
  const deleteFile = async () => {
    let tempLabel = "",
    tempAlert = "";
    const data = await deleteDocument(value);
    if (data.resultCode === 0) {
      // Caso positivo
      isDeleted(value);
    } else {
      tempLabel = "Ocurrió un error al eliminar el archivo, intente nuevamente.";
    }
    setError(tempLabel);
    isSelected(false);
    setOpenModal(false);
  };

  const handleClick = async (value, name) => {
    let tempLabel = "", tempAlert = "";
    setIsDownloading(true);
    const data = await downloadDocument(value);
    if (!data.resultCode) {
      /* SE PROCEDE AL DESCARGO */
      fileDownload(data, name);
    } else {
      tempLabel = "Ocurrió un error al descargar el archivo, intente nuevamente.";
    }
    setError(tempLabel);
    setIsDownloading(false);
  };

  return (
    <div className="file-item">
      <div className="file-name">
        <div className="file-text">
          <p className="text-secondary" style={{ marginBottom: "0px" }}>
            {name}
          </p>
        </div>
        {size !== null && (
          <div className="file-size">
            <p className="text-secondary" style={{ marginBottom: "0px" }}>
              {` (${prettyBytes(size)})`}
            </p>
          </div>
        )}
      </div>
      <div className="icons">
        {toDelete && (
          <div className="bin-icon text-secondary">
            <Button
              type="btn-outline-white"
              override="override-btn-outline-icon"
              id={`trash-${name}`}
              title={
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ fontSize: "1rem", color: "#004EA8" }}
                />
              }
              onButtonClick={handleModal}
            />
          </div>
        )}
        {toDownload && isUploaded && (
          <div className="download-icon text-secondary">
            <Button
              type="btn-outline-white"
              override="override-btn-outline-icon"
              id={`trash-${name}`}
              title={
                <FontAwesomeIcon
                  icon={isDownloading ? faSpinner : faDownload}
                  spinPulse={isDownloading}
                  style={{ fontSize: "1rem", color: "#004EA8" }}
                />
              }
              onButtonClick={() => {
                handleClick(value, name);
              }}
            />
          </div>
        )}
        {!isUploaded && (
          <div className="download-icon text-secondary">
            <FontAwesomeIcon
              icon={faSpinner}
              spinPulse={true}
              style={{
                fontSize: "1.25rem",
                color: "#004EA8",
              }}
            />
          </div>
        )}
      </div>

      {/*------------MANEJO DE MODALES PARA LA SOBRECARGA DE BOTONES------------*/}
      {selected && (
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          title="Eliminado de Documentos"
          size="md"
          body={`¿Desea eliminar el archivo ${name}?`}
          footer={["Confirmar", "Cerrar"]}
          handleConfirm={() => deleteFile()}
        />
      )}
    </div>
  );
}

export default FileItem;
