import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";

import FileDownload from "js-file-download";

import Button from "../../../../components/Buttons/Buttons.js";

import "../PresentacionDoc.scss";
import { formInputs } from "../../../../constants/Forms/Forms_PresentacionDoc";
import { EMAIL_VERIFICATION, JUST_NUMBERS } from "../../../../constants/Regex";

import {
  validateCode,
  senderData,
  documentData,
  responseDocument,
} from "../../../../services/docSubmission";

import DatosRemitente from "./DatosRemitente.js";
import DatosDocumento from "./DatosDocumento.js";
import Cargo from "./Cargo.js";
import Modals from "../../../../components/Modals/Modals";

const PageValidation = async (page, formData, prev_correo, temp, tempLabel) => {
  if (page === 1) {
    //Validación mediante el código
    let data;
    if (
      formData.emailValidacion.length === 6 &&
      prev_correo !== formData.correo
    )
      data = await validateCode(formData.emailValidacion, formData.correo);

    /* Validaciones de Datos Generales */
    if (formData.tipo_doc === "") {
      temp.tipo_doc = true;
      temp.numero = false;
      temp.nombre = false;
    } else {
      temp.tipo_doc = false;
      if (
        formData.tipo_doc !== "sin_ruc" &&
        (!formData.numero ||
          !JUST_NUMBERS.test(formData.numero) ||
          (formData.tipo_doc === "dni" &&
            formData.numero.toString().length !== 8) ||
          (formData.tipo_doc === "ruc" &&
            formData.numero.toString().length !== 11))
      ) {
        if (
          !JUST_NUMBERS.test(formData.numero) ||
          (formData.tipo_doc === "dni" &&
            formData.numero.toString().length !== 8) ||
          (formData.tipo_doc === "ruc" &&
            formData.numero.toString().length !== 11)
        )
          tempLabel = "Complete adecuadamente los campos solicitados";
        temp.numero = true;
      } else temp.numero = false;
      if (!formData.nombre) temp.nombre = true;
      else temp.nombre = false;
    }
    /* Validaciones de Datos de Contacto */
    if (!formData.correo || !EMAIL_VERIFICATION.test(formData.correo)) {
      if (!EMAIL_VERIFICATION.test(formData.correo))
        tempLabel = "Complete adecuadamente los campos solicitados";
      temp.correo = true;
    }
    //else -> more validations-
    else {
      temp.correo = false;
      /* VALIDACIÓN DE RESPUESTA CORRECTA EN EL BACKEND DEL CODIGO */
      if (prev_correo !== formData.correo) {
        temp.emailValidacion = !formData.emailValidacion;
        if (
          !formData.emailValidacion ||
          formData.emailValidacion.length !== 6
        ) {
          temp.emailValidacion = true;
        } else {
          if (data.resultCode === 0) temp.emailValidacion = false;
          else {
            temp.emailValidacion = true;
            if (data.resultCode !== 400)
              tempLabel = "Ingrese el código de verificación adecuado";
            else
              tempLabel =
                "En estos momentos se encuentran problemas con el servidor.";
          }
        }
      } else temp.emailValidacion = false;
      /* VALIDACIÓN DE RESPUESTA CORRECTA EN EL BACKEND DEL CODIGO */
    }
    /* Validaciones de Conformidad */
    temp.validacion = !formData.validacion;
  }
  if (page === 2) {
    /* Validaciones de Datos en Relación al Documento */
    if (formData.tiene_expediente === null) {
      temp.tiene_expediente = true;
      temp.num_exp_1 = false;
      temp.num_exp_2 = false;
      temp.num_exp_3 = false;
      temp.asunto = false;
      temp.documento_principal = false;
      temp.documentos_anexos = false;
    } else {
      temp.tiene_expediente = false;
      const codeValue = formData.num_exp_1.concat(
        formData.num_exp_2,
        formData.num_exp_3
      );
      if (
        formData.tiene_expediente &&
        (!formData.num_exp_1 ||
          !formData.num_exp_2 ||
          !formData.num_exp_3 ||
          !JUST_NUMBERS.test(codeValue))
      ) {
        temp.num_exp_1 = true;
        temp.num_exp_2 = true;
        temp.num_exp_3 = true;
        if (!JUST_NUMBERS.test(codeValue))
          tempLabel = "Complete adecuadamente los campos solicitados";
      } else {
        temp.num_exp_1 = false;
        temp.num_exp_2 = false;
        temp.num_exp_3 = false;
      }
      if (formData.asunto === "" || formData.asunto.length > 400)
        temp.asunto = true;
      else temp.asunto = false;
      /* Validaciones de Documentos */
      if (formData.documento_principal.length <= 0) {
        temp.documento_principal = true;
      } else temp.documento_principal = false;
    }
  }

  return { temp, tempLabel };
};

const submitForm = async (formData) => {
  /* FUNCION PARA LA SUBIDA DEL ARCHIVO CORRESPONDIENTE */
  let data;
  let body;
  if (formData.tipo_doc === "sin_ruc")
    body = {
      documentSubmissionNumber: !formData.documentSubmissionNumber
        ? ""
        : formData.documentSubmissionNumber,
      documentType: formData.tipo_doc,
      fullName: formData.nombre,
      cellPhone: formData.celular,
      phoneNumber: formData.telefono,
      phoneExtension: formData.anexo,
      email: formData.correo,
    };
  else
    body = {
      documentSubmissionNumber: !formData.documentSubmissionNumber
        ? ""
        : formData.documentSubmissionNumber,
      documentType: formData.tipo_doc,
      documentNumber: formData.numero,
      fullName: formData.nombre,
      cellPhone: formData.celular,
      phoneNumber: formData.telefono,
      phoneExtension: formData.anexo,
      email: formData.correo,
    };
  data = await senderData(body);

  return data;
};

const finalForm = async (formData) => {
  /* FUNCION PARA LA SUBIDA DEL ARCHIVO CORRESPONDIENTE */
  let data;
  const fileNumber = formData.num_exp_1.concat(
    "-",
    formData.num_exp_2,
    "-",
    formData.num_exp_3
  );
  let body;
  if (fileNumber.length >= 5)
    body = {
      documentSubmissionNumber: formData.documentSubmissionNumber,
      hasFile: formData.tiene_expediente,
      fileNumber: fileNumber,
      subject: formData.asunto,
    };
  else
    body = {
      documentSubmissionNumber: formData.documentSubmissionNumber,
      hasFile: formData.tiene_expediente,
      subject: formData.asunto,
    };
  data = await documentData(body);

  return data;
};

const FormContainer = (props) => {
  const {
    docNumber = null,
    page,
    setPage,
    isValid,
    setIsValid,
    senderData,
    docData,
    show,
    finalData,
    setFinalData,
    setShow,
  } = props;

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    //Datos Generales
    documentSubmissionNumber: docNumber,
    tipo_doc: !senderData
      ? ""
      : (senderData.data.documentType === 1 && "dni") ||
      (senderData.data.documentType === 2 && "ruc") ||
      (senderData.data.documentType === 3 && "sin_ruc"), ///1, 2, 3
    numero:
      !senderData || !senderData.data.documentNumber
        ? ""
        : senderData.data.documentNumber,
    nombre: !senderData ? "" : senderData.data.fullName,
    //Datos de Contacto
    celular:
      !senderData || !senderData.data.cellPhone
        ? ""
        : senderData.data.cellPhone,
    telefono:
      !senderData || !senderData.data.phoneNumber
        ? ""
        : senderData.data.phoneNumber,
    anexo:
      !senderData || !senderData.data.phoneExtension
        ? ""
        : senderData.data.phoneExtension,
    correo: !senderData ? "" : senderData.data.email,
    emailValidacion: "",
    validacion: !senderData ? false : true,
    //Datos en Relación a los Documentos
    tiene_expediente:
      !docData || !docData.documentData.fileNumber
        ? null
        : docData.documentData.hasFile,
    num_exp_1:
      !docData || !docData.documentData.fileNumber
        ? ""
        : docData.documentData.fileNumber.slice(0, 4),
    num_exp_2:
      !docData || !docData.documentData.fileNumber
        ? ""
        : docData.documentData.fileNumber.slice(5, 8),
    num_exp_3:
      !docData || !docData.documentData.fileNumber
        ? ""
        : docData.documentData.fileNumber.slice(-2),
    asunto:
      !docData || !docData.documentData.fileNumber
        ? ""
        : docData.documentData.subject,
    //Datos de los Documentos
    documento_principal: [],
    documentos_anexos: [],
  });

  const [errors, setErrors] = useState({
    //Datos Generales
    tipo_doc: false,
    numero: false,
    nombre: false,
    //Datos de Contacto
    correo: false,
    emailValidacion: false,
    validacion: false,
    //Datos de los Documentos
    tiene_expediente: false,
    num_exp: false,
    asunto: false,
    documento_principal: false,
    documentos_anexos: false,
  });

  const [disabled, isDisabled] = useState(page !== 3);
  const [finishDoc, setFinishDoc] = useState(false);

  function setError(message) {
    setIsValid({
      ...isValid,
      type: "alert-warning",
      label: message,
    });
  }

  //Función necesaria para reconocer que se mostrará en la página
  const PageDisplay = () => {
    if (page === 1) {
      return (
        <DatosRemitente
          input={formInputs[0]}
          formData={formData}
          setFormData={setFormData}
          prev_correo={!senderData ? null : senderData.data.email}
          errors={errors}
          setErrors={setErrors}
        />
      );
    } else if (page === 2) {
      return (
        <DatosDocumento
          input={formInputs[1]}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          isValid={isValid}
          setIsValid={setIsValid}
          setShow={setShow}
        />
      );
    } else {
      return <Cargo dataFinal={finalData} setError={setError} />;
    }
  };
  //Funciones para la validación de los datos y el respectivo submit de estos
  const validation = () => {
    let temp = {
      ...errors,
    };
    let tempLabel = "";
    /* Validaciones de Datos del Remitente */
    PageValidation(
      page,
      formData,
      !senderData ? null : senderData.data.email,
      temp,
      tempLabel
    ).then((data) => {
      temp = data.temp;
      tempLabel = data.tempLabel;
      // Fin de la validación
      setErrors(temp);

      if (Object.values(temp).indexOf(true) > -1) {
        if (tempLabel === "")
          tempLabel = "Complete todos los campos obligatorios";
        setIsValid({
          ...isValid,
          valid: false,
          type: "alert-danger",
          label: tempLabel,
        });
        setShow(true);
        return;
      }
      setIsValid({ ...isValid, valid: true, type: null });
      setShow(false);
      if (page === 2) setFinishDoc(true);
    });
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    if (page === 1) {
      setShow(true);
      setIsValid({
        ...isValid,
        type: "alert-loading",
        label: "Se están validando sus datos.",
      });
      validation();
      setIsValid({
        ...isValid,
        type: "alert-loading",
        label: "Se están procesando sus datos.",
      });
    }
    if (page === 2) {
      setShow(true);
      setIsValid({
        ...isValid,
        type: "alert-loading",
        label: "Se están validando sus datos.",
      });
      validation();
    }
    if (page === 3) {
      //Session close
      sessionStorage.removeItem("submissionData");
      try {
        window.close();
        console.log("Si se cerro");
      } catch (error) {
        console.log(error);
      }
      //navigate("/mesa_partes");
    }
  };

  const handleFinish = () => {
    setFinishDoc(false);
    setShow(true);
    setIsValid({
      ...isValid,
      type: "alert-loading",
      label: "Se están procesando sus datos.",
    });
    finalForm(formData).then((data) => {
      if (data.resultCode === 0) {
        sessionStorage.setItem(
          "submissionData",
          JSON.stringify({
            code: data.documentSubmissionNumber,
            body: data,
            page: page,
          })
        );
        setFinalData(data);
        localStorage.removeItem("submissionData");
        setPage((currPage) => currPage + 1);
        setIsValid({
          ...isValid,
          valid: false,
          type: "alert-success",
          label: "Su registro se completó con éxito.",
        });
      } else if (data.resultCode === 400) {
        if (data.message.resultCode === 1002) {
          setIsValid({
            ...isValid,
            valid: false,
            type: "alert-danger",
            label: "El número de expediente ingresado no existe.",
          });
        }
      } else {
        setIsValid({
          ...isValid,
          valid: false,
          type: "alert-danger",
          label: "En estos momentos se encuentran problemas con el servidor.",
        });
      }
      setShow(true);
    });
  };

  const handlePrev = async (curPage) => {
    if (curPage === 3) {
      const data = await responseDocument(
        finalData.senderData.documentSubmissionNumber
      );
      if (!data.resultCode) {
        /* SE PROCEDE AL DESCARGO */
        FileDownload(
          data,
          `Resumen_${finalData.senderData.documentSubmissionNumber}.pdf`
        );
      }
    } else setPage((currPage) => currPage - 1);
  };

  function isFilled() {
    if (page === 1) {
      /* Validaciones de Datos Generales */
      if (formData.tipo_doc === "") return false;
      if (
        formData.tipo_doc !== "sin_ruc" &&
        (!formData.numero ||
          !JUST_NUMBERS.test(formData.numero) ||
          (formData.tipo_doc === "dni" &&
            formData.numero.toString().length !== 8) ||
          (formData.tipo_doc === "ruc" &&
            formData.numero.toString().length !== 11))
      )
        return false;
      if (!formData.nombre) return false;
      /* Validaciones de Datos de Contacto */
      if (!formData.correo || !EMAIL_VERIFICATION.test(formData.correo))
        return false;
      if (!senderData ? true : senderData.data.email !== formData.correo) {
        if (!formData.emailValidacion || formData.emailValidacion.length !== 6)
          return false;
      }
      /* Validaciones de Conformidad */
      if (!formData.validacion) return false;
    }
    if (page === 2) {
      /* Validaciones de Datos en Relación al Documento */
      if (formData.tiene_expediente === null) return false;
      if (
        formData.tiene_expediente &&
        (!formData.num_exp_1 || !formData.num_exp_2 || !formData.num_exp_3)
      )
        return false;
      if (formData.asunto === "" || formData.asunto.length > 400) return false;
      /* Validaciones de Documentos */
      if (formData.documento_principal.length <= 0) return false;
    }
    if (page === 3) {
      return false;
    }
    return true;
  }

  useUpdateEffect(() => {
    const valid = isFilled();
    isDisabled(!valid);
  }, [formData]);

  //PARA EL LLENADO INICIAL DE LOS DOCUMENTOS EN CASO DE QUE ESTÉN EN LA SEGUNDA PÁGINA
  useEffect(() => {
    if (docData) {
      const principal = [];
      const anexos = [];
      for (const file of docData.filesMetadata) {
        const fileFormatted = {
          name: file.fileName,
          size: file.bytesSize,
          type: file.contentType,
          mainFile: file.mainFile,
          value: file.hashDocument,
        };
        if (file.mainFile) principal.push(fileFormatted);
        else anexos.push(fileFormatted);
      }
      setFormData({
        ...formData,
        documento_principal: principal,
        documentos_anexos: anexos,
      });
    }
  }, []);

  useUpdateEffect(() => {
    if (isValid.valid) {
      if (page === 1) {
        submitForm(formData).then((data) => {
          if (data.resultCode === 0) {
            localStorage.setItem(
              "submissionData",
              JSON.stringify({
                code: data.data.value,
                page: page,
              })
            );
            setFormData({
              ...formData,
              documentSubmissionNumber: data.data.value,
            });
            setPage((currPage) => currPage + 1);
            setIsValid({
              ...isValid,
              valid: false,
              type: "alert-success",
              label: "Se guardaron adecuadamente sus datos.",
            });
          } else if (data.resultCode === 400) {
            setIsValid({
              ...isValid,
              valid: false,
              type: "alert-danger",
              label: "Ingrese adecuadamente los datos solicitados.",
            });
          } else {
            setIsValid({
              ...isValid,
              valid: false,
              type: "alert-danger",
              label:
                "En estos momentos se encuentran problemas con el servidor.",
            });
          }
          setShow(true);
        });
      }
    }
  }, [isValid.valid]);
  return (
    <>
      <form
        className="needs-validation form-class"
        noValidate
        onSubmit={(e) => {
          handleNextStep(e);
        }}
      >
        <div className="body">{PageDisplay()}</div>
        <div className="footer">
          {page < 3 && (
            <div className="footer-button">
              <Button
                type="btn-rounded"
                title={page >= 3 ? "Descargar PDF" : "Anterior"}
                color="btn-secondary"
                buttonDisabled={page === 1}
                onButtonClick={() => {
                  handlePrev(page);
                }}
              />
            </div>
          )}
          <div className="footer-button">
            <Button
              submit
              type="btn-rounded"
              buttonDisabled={disabled}
              title={page >= 3 ? "Cerrar" : "Siguiente"}
              color="btn-secondary"
            />
          </div>
        </div>
      </form>
      {/*------------MANEJO DE MODALES PARA LA SOBRECARGA DE BOTONES------------*/}
      <Modals
        openModal={finishDoc}
        setOpenModal={setFinishDoc}
        title="Envío de Documentos"
        size="md"
        body={`¿Desea confirmar el envío de documentos?`}
        footer={["Confirmar", "Cerrar"]}
        handleConfirm={handleFinish}
      />
    </>
  );
};

export default FormContainer;
