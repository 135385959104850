import React from "react";
import "./Modals.scss";

import Button from "../Buttons/Buttons";
import Modal from "react-bootstrap/Modal";

/* Componente reutilizable con nombre genérico
  -> MANEJO DE MODALES EN EL SISTEMA -> CONOCIDOS COMO POPUP TAMBIÉN */
function Modals(props) {
  const {
    openModal = false,
    setOpenModal,
    size,
    centered = true,
    bd_static = false,
    closeButton = false,
    title,
    body,
    footer,
    handleCancel = null,
    handleConfirm = null,
  } = props;
  /*
    [openModal, setOpenModal] -> verifica si el modal está activado o no
    centered -> si el modal está centrado -> caso contrario se podrá mover
    size -> para el dialogClassName -> size en tamaño -> sm, xl, md, lg
    bd_static -> Si se coloca en true el modal se configurará para que no se pueda salir si se presiona fuera o por teclado
    closeButton -> si existirá un botón para cerrar el modal
    title -> el titulo que se adecua al Modal [Funciona como texto o un componente separado]
    {body} -> componente heredado del Modal como tal...
    {
      RECOMENDACIÓN-> HACER USO DEL DIV->MODAL-FOOTER O MODAL-BODY para la creación del body
    }
    {footer} -> componente heredado del modal que permite el manejo de un footer -> en modo simple se maneja como un arreglo... 
                ['titulo_boton_Aceptar', 'titulo_boton_Cancelar'] -> 1 :: ['titulo_boton_Aceptar'] 
    */

  const handleClose = () => setOpenModal(false);

  return (
    <Modal
      show={openModal}
      onHide={handleClose}
      size={size}
      centered={centered}
      backdrop={bd_static ? "static" : true}
      keyboard={!bd_static}
    >
      <div className="main-popup">
        <div className="popup-header">
          {title && (
            <Modal.Header closeButton={closeButton}>
              <Modal.Title>
                {typeof title === "string" || title instanceof String ? (
                  <div className="popup-title">
                    <h3 className="text-primary">{title}</h3>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    {!closeButton && (
                      <img
                        src="/assets/icons/modal-vector.svg"
                        className="d-inline-block"
                        alt="modal-vector"
                      />
                    )}
                  </div>
                ) : (
                  { title }
                )}
              </Modal.Title>
            </Modal.Header>
          )}
        </div>
        <div className="popup-body">
          <Modal.Body>
            {typeof body === "string" || body instanceof String ? (
              <h5 className="text-primary">{body}</h5>
            ) : (
              { body }
            )}
          </Modal.Body>
        </div>
        {footer && (
          <div className="popup-footer">
            <Modal.Footer>
              {footer.length > 0 ? (
                <>
                  <div className="cancel-button">
                    {footer.length === 2 && (
                      <Button
                        type="btn-rounded"
                        color="btn-dark"
                        closeModal={true}
                        title={footer[1]}
                        onButtonClick={
                          handleCancel
                            ? handleCancel
                            : () => setOpenModal(false)
                        }
                      />
                    )}
                  </div>
                  <div className="accept-button">
                    {footer.length >= 1 && (
                      <Button
                        type="btn-rounded"
                        color="btn-secondary"
                        title={footer[0]}
                        onButtonClick={
                          handleConfirm
                            ? handleConfirm
                            : () => setOpenModal(false)
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                { footer }
              )}
            </Modal.Footer>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default Modals;
