export const JUST_NUMBERS = /^[0-9\b]+$/;
export const PATTERN_NUMBERS = "^[0-9\b]+$";
export const REAL_NUMBERS = /[+-]?(\d*\.\d+|\d+\.\d*|\d+)/;
export const JUST_CHARS = /^[a-zA-Z0-9 ]*$/;
export const TRIM_STRING = /^[\s]*(.*?)[\s]*$/;
export const CELLPHONE =
  /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;
export const EMAIL_VERIFICATION =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+[a-zA-Z0-9.-]*\.[a-zA-Z]{2,4}$/;
export const EMAIL_PATTERN =
  "^[a-z0-9._%+-]+@[a-z0-9-]+[a-z0-9.-]*.[a-z]{2,4}$";
