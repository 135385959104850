import React, { useState, useEffect } from "react";
import "./Title.scss";

import Button from "../Buttons/Buttons";
//Barra de navegación que estará presente en todas las pantallas
//position-absolute top-0 start-50 translate-middle
function Title(props) {
  /* Title -> se refiere al titulo
    Subtitle -> se refiere al subtitulo
    Expand -> Si se expandirá la barra naranja del titulo
    Back -> Si se manejará un botón que regresará a la pantalla anterior
    TextColor -> Color del texto referente [text-white || text-primary]*/
  const { title, subtitle, expand, back, route, textColor } = props;

  //max-width: 660px

  const [showReturn, setShowReturn] = useState(null);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 660) {
        setShowReturn(true);
      } else {
        setShowReturn(false);
      }
    }

    handleResize(); // set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window]); // include the window object in the dependency array

  return (
    showReturn !== null && (
      <div className="main-title" id="main-title">
        {showReturn && back && (
          <div className="title-button">
            <Button
              title="<"
              type="btn-rounded"
              anchor
              route={route}
              color="btn-secondary"
            />
          </div>
        )}
        <h1 className={`${textColor}`}>{title}</h1>
        {!expand ? (
          <hr className="hr-title"></hr>
        ) : (
          <hr className="hr-title hr-title-expand"></hr>
        )}
        {!back ? (
          <>
            {subtitle && (
              <div className="wrap-subtitle">
                {subtitle && (
                  <section className="subtitle-text">
                    <h2 className={`${textColor}`}>{subtitle}</h2>
                  </section>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {subtitle && (
              <div className="wrap-subtitle">
                {subtitle && (
                  <section className="subtitle-text">
                    <h2 className={`${textColor}`}>{subtitle}</h2>
                  </section>
                )}
                {!showReturn && (
                  <section className="subtitle-button">
                    <Button
                      title="Regresar"
                      type="btn-rounded"
                      anchor
                      route={route}
                      color="btn-secondary"
                    />
                  </section>
                )}
              </div>
            )}
          </>
        )}
      </div>
    )
  );
}

export default Title;
