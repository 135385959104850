import React, { useState } from "react";
import "../Form.scss";

/* Componente reutilizable con nombre genérico
  -> INPUT TEXT para el manejo de FORMS */
function Select(props) {
  /*  id -> IDENTIFICADOR UNICO PARA EL FORM
      label -> se refiere al nombre del label que se manejará
      helper -> definido como un apoyo debajo de la casilla del select 

      options -> Objeto de atributos
      {
        value -> El valor que se atribuirá al select cuando se seleccione la opción
        label -> El nombre del campo en el combobox
      } 

      selection -> se define como la casilla seleccionada -> DEFAULT = false === campo predefinido

      onChange -> para evaluar el cambio cosntante por eventos
      required -> Para futuras validaciones, si es un campo obligatorio
      disabled -> Por defecto en falso | maneja estados de activación
      errors -> TRUE OR FALSE -> En caso se active un caso de error se cambia a rojo -> otro estado
      feedback -> feedback en caso haya un error
      ...other para otras propiedades que se puedan ir adquiriendo*/
  const {
    id,
    label,
    helper,
    options,
    selection = "",
    onChange,
    errors = false,
    feedback,
    ...selectProps
  } = props;

  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <>
      {label && (
        <label className="form-label">
          <h4 className="text-primary">{label}</h4>
        </label>
      )}
      <select
        className={`form-select text-primary border-primary custom-select`}
        id={id}
        value={selection === "" ? undefined : selection}
        onChange={onChange}
        onBlur={handleFocus}
        focused={!errors ? focused.toString() : "true"}
        {...selectProps}
      >
        {options.map((option, index) => (
          /* ES NECESARIO QUE LA PRIMERA OPCION SEA EL QUE SE MANEJARÁ POR DEFECTO */
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {helper && (
        <div
          id={`helper_${id}`}
          className={`form-text ${feedback && "valid-div"}`}
        >
          <p className="text-dark">{helper}</p>
        </div>
      )}
      {feedback && (
        <div id={`helperror_${id}`} className="form-text invalid-div">
          <p className="text-danger">{feedback}</p>
        </div>
      )}
      {!helper && (
        <div
          id={`none_${id}`}
          className={`margin-bottom ${feedback && "valid-div"}`}
        ></div>
      )}
    </>
  );
}

export default Select;
