import React from 'react';
import { Routes , Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home.js';
import MesaPartes from './pages/MesaPartes/MesaPartes.js';
import PresentacionDoc from './pages/MesaPartes/Presentacion_Documentos/PresentacionDoc.js';

import NavBar from './components/NavBar/NavBar.js'

import './main.scss';


const App = () => {
  return(
    <div className = "wrapper" id="main">
      {/*Main components on each page*/}
      <div className = "app-navbar">
        <NavBar/>
      </div>
      <div className = "app-component bg-white">
        <Routes >
          {/* Redirecciosnamiento a Home - general */}
          <Route path="/" exact element={<Navigate to = '/home' />} />
          {/* Redirecciosnamiento a Home - general */}
          <Route path="/home" exact element = {< Home />} />
          {/* Redireccionamiento a mesa de partes*/}
          <Route path="/mesa_partes" exact element = {< MesaPartes />} />
          {/* Redireccionamiento a presentación de documentos*/}
          <Route path="/mesa_partes/documentos" exact element = {< PresentacionDoc />} />
        </Routes >
      </div>
    </div>
  );
};
export default App;