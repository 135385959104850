import React, { useState, useEffect, useRef } from "react";
import { JUST_NUMBERS } from "../../../constants/Regex";
import Input from "./Input";

// import OtpInput from "react18-input-otp";

function SplitInput(props) {
  const {
    id,
    label,
    helper,
    inputs, //{that contains ids, names, values, onChanges}
    feedback,
    override,
    errors = false,
    onChange_1,
    onChange_2,
    onChange_3,
    value_1,
    value_2,
    value_3,
    ...inputProps
  } = props;

  // const inputElement = useRef(null);

  // function handleSubmit(e) {
  //   inputElement.current.click();
  // }

  const [splitErrors, setSplitErrors] = useState(errors);

  const [focused1, setFocused1] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [focused3, setFocused3] = useState(false);

  const handleFocus_1 = (e) => {
    setFocused1(true);
  };
  const handleFocus_2 = (e) => {
    setFocused2(true);
  };
  const handleFocus_3 = (e) => {
    setFocused3(true);
  };

  const handleInput = (evt) => {
    var theEvent = evt || window.evt;

    // Handle paste
    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }

    // onChange(evt);
  };

  useEffect(() => {
    if (focused1 && focused2 && focused3) {
      const codeValue = value_1.concat(value_2, value_3);
      if (codeValue.length < 6 || !JUST_NUMBERS.test(codeValue))
        setSplitErrors(true);
      else setSplitErrors(false);
    }
  }, [value_1, value_2, value_3]);

  return (
    <div className="split-input">
      {label && (
        <label className="form-label text-primary">
          <h4>{label}</h4>
        </label>
      )}

      <div className="flex-inputs">
        <div className="input">
          <input
            id={inputs[0].id}
            name={inputs[0].name}
            value={inputs[0].value}
            className={`form-control text-primary ${"border-primary"} override-${override}`}
            onChange={onChange_1}
            onKeyPress={handleInput}
            onBlur={handleFocus_1}
            focused={!splitErrors ? focused1.toString() : "true"}
            {...inputProps}
          />
        </div>
        <div className="score">
          <h4 className="text-center">-</h4>
        </div>
        <div className="input">
          <input
            id={inputs[1].id}
            name={inputs[1].name}
            value={inputs[1].value}
            className={`form-control text-primary ${"border-primary"} override-${override}`}
            onChange={onChange_2}
            onKeyPress={handleInput}
            onBlur={handleFocus_2}
            focused={!splitErrors ? focused2.toString() : "true"}
            {...inputProps}
          />
        </div>
        <div className="score">
          <h4 className="text-center">-</h4>
        </div>
        <div className="input">
          <input
            id={inputs[2].id}
            name={inputs[2].name}
            value={inputs[2].value}
            className={`form-control text-primary ${"border-primary"} override-${override}`}
            onChange={onChange_3}
            onKeyPress={handleInput}
            onBlur={handleFocus_3}
            focused={!splitErrors ? focused3.toString() : "true"}
            {...inputProps}
          />
        </div>
      </div>
      <div className="helper">
        {helper && !(feedback && splitErrors) && (
          <div
            id={`helper_${id}`}
            className={`form-text ${feedback && "valid-div"}`}
          >
            <p className="text-dark">{helper}</p>
          </div>
        )}
        {feedback && splitErrors && (
          <div id={`helperror_${id}`} className="form-text">
            <p className="text-danger">{feedback}</p>
          </div>
        )}
        {!(helper && feedback && splitErrors) && (
          <div
            id={`none_${id}`}
            className={`margin-bottom ${feedback && "valid-div"}`}
          ></div>
        )}
      </div>
    </div>
  );
}

export default SplitInput;
