import React from "react";
import Step from "./Step/Step";

import './Stepper.scss';

function Stepper (props){
  const {labelArray, currentStep} = props;

  /* Funcion principal del Stepper -> Manejar los pasos para los Formularios que deben ser llenados en el sistema */
  return (
    <div className="stepWrapper">
      {labelArray.map((item, index) => 
        <div className="step" key= {index}>
          <Step key={index} index={index} label = {item.name} icon = {item.icon} selected={currentStep === index + 1}></Step>
          {(index !== 0) && <div className = "line-separator-start"/>}
          {(index !== labelArray.length-1) && <div className = "line-separator-end"/>}
        </div>
        )}
    </div>
  );  
}

export default Stepper;