import React from "react";
import "./Card.scss";

import Button from "../../Buttons/Buttons";

function Card(props) {
  const { icon, title, body, height, route, newTab = false } = props;

  const onNewTab = () => {
    const newTab = window.open("", "_blank");
    newTab.location.href = route;
  };

  return (
    <>
      {height === "max" ? (
        <div className="main wrapper-max justify-content-center">
          <div className="card card-max text-white bg-dark">
            <div className="card-body">
              <div className="title">
                <h2 className="card-title text-center ">{title} </h2>
              </div>
              <hr className="hr-divider"></hr>
              <h4 className="card-text text-center">{body}</h4>
            </div>
          </div>
          <div>
            {!newTab ? (
              <Button
                title="INGRESAR"
                type="btn-rounded-primary"
                anchor
                route={route}
                color="btn-secondary"
              />
            ) : (
              <Button
                title="INGRESAR"
                type="btn-rounded-primary"
                onButtonClick={() => {
                  onNewTab();
                }}
                color="btn-secondary"
              />
            )}
          </div>
        </div>
      ) : (
        <div className="main wrapper-min">
          <div className="card card-min text-white bg-dark">
            <div className="card-body">
              <div className="title">
                <h2 className="card-title text-center">{title} </h2>
                <img
                  src={icon}
                  className="d-inline-block"
                  alt={`icon-${title}`}
                />
              </div>
              <hr className="hr-divider"></hr>
              <h4 className="card-text text-center">{body}</h4>
            </div>
          </div>
          <div>
            {!newTab ? (
              <Button
                title="INGRESAR"
                type="btn-rounded-primary"
                anchor
                route={route}
                color="btn-secondary"
              />
            ) : (
              <Button
                title="INGRESAR"
                type="btn-rounded-primary"
                onClick={onNewTab}
                color="btn-secondary"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Card;
