import React, { useState, useRef, useEffect } from "react";
import useFetch from "../../../hooks/useFetch.js";
import useUpdatedEffect from "../../../hooks/useUpdateEffect";

import Title from "../../../components/Title/Title.js";
import Stepper from "../../../components/Stepper/Stepper.js";
import Alert from "../../../components/Alerts/Alert.js";

import "./PresentacionDoc.scss";

//Paginas para el Form
import FormContainer from "./Forms/FormContainer";
import Spinner from "../../../components/Loaders/Spinner.js";

//Vista por defecto que manejará a todos los distintos componentes a mostrar de la presentación de documentos
const PresentacionDoc = () => {
  
  /*--------------DEFINICION DE CONSTANTES-----------------*/
  const submissionData = useRef(
    localStorage.getItem("submissionData")
      ? JSON.parse(localStorage.getItem("submissionData"))
      : JSON.parse(sessionStorage.getItem("submissionData"))
  );

  const [page, setPage] = useState(
    submissionData.current ? submissionData.current.page + 1 : 1
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      localStorage.removeItem("submissionData");
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [isValid, setIsValid] = useState({
    valid: null,
    type: null,
    label: "",
  });

  const [show, setShow] = useState(false);
  const [finalData, setFinalData] = useState(
    submissionData.current !== null ? submissionData.current.body : null
  );

  //Necesario para el Stepper a manejar
  const labelArray = [
    { name: "Remitente", icon: "/assets/icons/Business_Building.svg" },
    {
      name: "Documento",
      icon: "/assets/icons/Terms_and_Conditions.svg",
    },
    { name: "Resumen", icon: "/assets/icons/Check_File.svg" },
  ];

  //LLAMADO AL BACKEND ->
  const {
    data: senderData,
    loading: send_loading,
    refetch,
  } = useFetch(
    `${process.env.REACT_APP_API_URL}/docSubmission/fetchSenderData`,
    {
      keyCode: process.env.REACT_APP_KEY_CODE,
      data: {
        value: submissionData.current ? submissionData.current.code : null,
      },
    },
    page === 1 && submissionData.current !== null
  );

  //TRAEMOS LOS DATOS DEL DOCUMENTO
  const { data: docData, loading: doc_loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/docSubmission/fetchDocumentData`,
    {
      keyCode: process.env.REACT_APP_KEY_CODE,
      data: {
        value: submissionData.current ? submissionData.current.code : null,
      },
    },
    page === 2 && submissionData.current !== null
  );

  useUpdatedEffect(() => {
    if (page === 1) refetch();
  }, [page]);

  return (
    <div className="mesa_partes-wrapper">
      <Title
        title="Sistema de Gestión Arbitral - Mesa de Partes Virtual"
        textColor="text-primary"
        expand
      ></Title>
      <div className="form">
        <div className="stepper">
          <Stepper labelArray={labelArray} currentStep={page}></Stepper>
        </div>
        <div className="form-container">
          {show === true && (
            <div className="hover-message">
              <Alert
                label={isValid.label}
                type={isValid.type}
                close={!(isValid.type === "alert-loading")}
                show={show}
                setShow={setShow}
              />
            </div>
          )}
          {/* FORM VALIDATION SUBMISSION */}
          {(page === 1 && !send_loading) ||
            (page === 2 && !doc_loading) ||
            page === 3 ? (
            <FormContainer
              page={page}
              docNumber={
                submissionData.current ? submissionData.current.code : null
              }
              setPage={setPage}
              isValid={isValid}
              setIsValid={setIsValid}
              show={show}
              setShow={setShow}
              senderData={
                senderData !== null
                  ? senderData.data.fullName !== null
                    ? senderData
                    : null
                  : null
              }
              docData={docData !== null ? docData : null}
              finalData={finalData}
              setFinalData={setFinalData}
            />
          ) : (
            <Spinner></Spinner>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresentacionDoc;
