import React, { useState, useEffect } from "react";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";

import SplitInput from "../../../../components/Form/Controls/SplitInput";
import FileSubmission from "../../../../components/Form/Controls/FileSubmission";
import Button from "../../../../components/Buttons/Buttons";
import FormContainer from "../../../../components/Form/FormContainer";
import TextArea from "../../../../components/Form/Controls/TextArea";

function DatosDocumento({
  input,
  formData,
  setFormData,
  errors,
  setErrors,
  isValid,
  setIsValid,
  setShow,
}) {
  const [tieneExpediente, setTieneExpediente] = useState(
    formData.tiene_expediente
  );

  const inputs = [
    { id: "exp_1", name: "expediente_1", value: formData.num_exp_1 },
    { id: "exp_2", name: "expediente_2", value: formData.num_exp_2 },
    { id: "exp_3", name: "expediente_3", value: formData.num_exp_3 },
  ];

  //console.log(formData);

  const savePrincipal = (arrayFiles) => {
    setFormData({ ...formData, documento_principal: arrayFiles });
  };
  const saveAnexos = (arrayFiles) => {
    setFormData({ ...formData, documentos_anexos: arrayFiles });
  };

  useEffect(() => {
    if (!tieneExpediente)
      setFormData({
        ...formData,
        num_exp_1: "",
        num_exp_2: "",
        num_exp_3: "",
      });
  }, [tieneExpediente]);

  return (
    <div className="datos-documento">
      <FormContainer title="En relación al documento">
        <div className="relacion-documento">
          <div className="botones-seleccion">
            <div className="select-button">
              <Button
                onButtonClick={() => {
                  setFormData({ ...formData, tiene_expediente: 1 });
                  setTieneExpediente(1);
                }}
                title={<h4>El escrito pertenece a un expediente</h4>}
                color="btn-secondary"
                buttonPressed={tieneExpediente}
                type={`${tieneExpediente ? "btn-rounded" : "btn-rounded-selection"
                  }`}
              />
            </div>
            <div className="select-button">
              <Button
                onButtonClick={() => {
                  setFormData({
                    ...formData,
                    tiene_expediente: 0,
                  });
                  setTieneExpediente(0);
                }}
                title={<h4>El escrito no pertenece a un expediente</h4>}
                color="btn-secondary"
                buttonPressed={!tieneExpediente && tieneExpediente !== null}
                type={`${!tieneExpediente && tieneExpediente !== null
                  ? "btn-rounded"
                  : "btn-rounded-selection"
                  }`}
              />
            </div>
          </div>
          {tieneExpediente === 1 && (
            <div className="num_expediente">
              <SplitInput
                inputs={inputs}
                onChange_1={(e) => {
                  setFormData({ ...formData, num_exp_1: e.target.value });
                }}
                onChange_2={(e) => {
                  setFormData({ ...formData, num_exp_2: e.target.value });
                }}
                onChange_3={(e) => {
                  setFormData({ ...formData, num_exp_3: e.target.value });
                }}
                value_1={formData.num_exp_1}
                value_2={formData.num_exp_2}
                value_3={formData.num_exp_3}
                errors={errors.num_exp}
                {...input[0]}
              />
            </div>
          )}
          {tieneExpediente !== null && (
            <div className="asunto">
              <TextArea
                onChange={(e) => {
                  setFormData({ ...formData, asunto: e.target.value });
                }}
                value={formData.asunto}
                {...input[1]}
              />
            </div>
          )}
        </div>
      </FormContainer>
      {tieneExpediente !== null && (
        <FormContainer title="Documentos">
          <div className="label" style={{ textAlign: 'justify', color: '#E2503D', fontSize: '1.1em' }}>
            <b>Recordatorio:</b> Los nombres de los documentos no deben superar los 30 caracteres
          </div><br></br>
          <div className="documentos">
            <div className="doc_principal">
              <FileSubmission
                type="primary"
                label="Documento principal - Solo un archivo PDF (Max. 1GB):"
                errors={errors.documento_principal}
                feedback="Se requiere la subida de un archivo principal"
                docNumber={formData.documentSubmissionNumber}
                files={formData}
                setFiles={setFormData}
                saveFiles={savePrincipal}
                isValid={isValid}
                setIsValid={setIsValid}
                setShow={setShow}
              />
            </div>
            <div className="doc_anexos">
              <FileSubmission
                type="secondary"
                label="Documentos anexos - Archivos PDF, WORD, EXCEL, JPG, DBF, MP3, MP4, ZIP, RAR, 7Z  (Max. 1GB por anexo):"
                errors={errors.documentos_anexos}
                docNumber={formData.documentSubmissionNumber}
                files={formData}
                setFiles={setFormData}
                saveFiles={saveAnexos}
                isValid={isValid}
                setIsValid={setIsValid}
                setShow={setShow}
              />
            </div>
          </div>
        </FormContainer>
      )}
    </div>
  );
}

export default DatosDocumento;
