import React, { useState } from "react";
import "../Form.scss";

import CustomInput from "./CustomInput";
import Button from "../../Buttons/Buttons";

import { requestCode } from "../../../services/docSubmission";

/* Componente reutilizable con nombre genérico
  -> VerificationSMS sirve para poder emitir un código de verificación en SMS */
function VerificationSMS(props) {
  /*
    title -> el titulo que obtendrá el botón 
    onButtonClick -> accion a realizar cuando se le da click al botón
    label -> se refiere al nombre del label que se manejará
    helper -> definido como un apoyo debajo del Verification SMS
    placeholder -> texto que aparece dentro de la casilla de input
    onChange -> para evaluar el cambio cosntante por eventos
    required -> Para futuras validaciones, si es un campo obligatorio
    disabled -> Por defecto en falso | maneja estados de activación
    errors -> TRUE OR FALSE -> En caso se active un caso de error se cambia a rojo -> otro estado
    feedback -> feedback en caso haya un error
    ...other para otras propiedades que se puedan ir adquiriendo*/
  const {
    id,
    title,
    onButtonClick,
    label,
    email, //IMPORTANTE PARA HACER USO DEL CODIGO DE VERIFICACIÓN
    helper,
    onChange,
    disabled = false,
    errors = false,
    feedback,
    openModal,
    setOpenModal,
    ...inputProps
  } = props;

  const handleShow = () => {
    requestCode(email);
    setOpenModal(true);
  };
  return (
    <>
      {label && <label className="form-label text-primary">{label}</label>}
      <div
        className={`verification-sms ${
          !disabled ? "verification-sms-active" : "verification-sms-unactive"
        }`}
      >
        {!disabled && (
          <div className="codigo">
            <Button
              type="btn-link"
              title={title}
              onButtonClick={onButtonClick ? onButtonClick : handleShow}
              buttonDisabled={disabled}
              modal={true}
              target="modalSMS"
            />
          </div>
        )}
        <div className="input">
          <CustomInput
            id={id}
            override="input-numero"
            color="text-warning"
            onChange={onChange}
            disabled={disabled}
            errors={errors}
            {...inputProps}
          />
        </div>
        <div className="helper">
          {helper && (
            <div id={`helper_${id}`} className={`form-text text-center`}>
              <p className="text-dark">{helper}</p>
            </div>
          )}
          {feedback && !disabled && (
            <div id={`helperror_${id}`} className="form-text text-center">
              <p className="text-danger">{feedback}</p>
            </div>
          )}
          {!(helper && feedback) && (
            <div id={`none_${id}`} className="margin-bottom"></div>
          )}
        </div>
      </div>
    </>
  );
}

export default VerificationSMS;
