import React from "react";
import Cards from "../../components/Cards/Cards.js";
import Title from "../../components/Title/Title.js";

import "./MesaPartes.scss";

const MesaPartes = () => {
  const cards = [
    {
      index: 1,
      title: "Presentación de solicitudes",
      body: "Ingresa aquí si deseas iniciar un nuevo arbitraje, una conciliación decisoria o un procedimiento de árbitro de emergencia. Esta es la única vía habilitada para la presentación de nuevas solicitudes. No se aceptarán solicitudes presentadas por correo electrónico o de manera presencial.",
      height: "max",
      route: "/mesa_partes/presentacion_solicitudes",
    },
    {
      index: 2,
      title: "Contestación de solicitudes",
      body: "Ingresa aquí y completa todos los datos necesarios para contestar la solicitud de un arbitraje y una conciliación decisoria, para lo cual necesitará conocer el número de expediente que corresponda.",
      height: "max",
      route: "/mesa_partes/contestacion_solicitudes",
    },
    {
      index: 3,
      title: "Presentación de documentos",
      body: "Ingresa aquí si deseas presentar escritos en un procedimiento de arbitraje u otro ya iniciado o documentos que deban tramitarse en el Centro. Esta es la única vía habilitada para la presentación de documentos.",
      height: "max",
      route: "/mesa_partes/documentos",
      newTab: true,
    },
  ];
  return (
    <div className="main-background">
      <div className="circles">
        <div className="circles-circle"></div>
        <div className="circles-circle"></div>
        <div className="circles-circle"></div>
      </div>
      <Title
        title="Sistema de Gestión Arbitral"
        subtitle="Mesa de Partes Virtual"
        back={true}
        route="/home"
        textColor="text-white"
      ></Title>
      {/* <div>
        <img src="/assets/main-background.svg" className="d-inline-block align-text-top" alt="ANÁLISIS Y RESOLUCIÓN DE CONFLICTOS PUCP"/>
      </div> */}
      <Cards cards={cards} height={"max"}></Cards>
    </div>
  );
};
export default MesaPartes;
