import { useEffect, useState } from "react";
import axios from "axios";

/* NOS PERMITE SIMPLIFICAR EL CÓDIGO CUANDO SE QUIERE TRAER DATOS DE UNA BASE DE DATOS */
function useFetch(url, attr, condition) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (condition) {
      setLoading(true);
      axios
        .post(url, attr)
        .then((response) => {
          if (response.data.resultCode === 0) setData(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  const refetch = () => {
    if (condition) {
      setLoading(true);
      axios
        .post(url, attr)
        .then((response) => {
          if (response.data.resultCode === 0) setData(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return { data, loading, error, refetch };
}

export default useFetch;
