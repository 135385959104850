import React from "react";

import "./Step.scss";

function Step(props) {
  const { selected, index, label, icon } = props;
  return (
    <div className={"stepBlock" + (selected ? " selected" : "")}>
      <div className="circleWrapper">
        <div className="circle">
          <img src={icon} className="d-inline-block" alt={`icon-${index}`} />
        </div>
      </div>
      <span>
        <h4 className="text-dark">{label}</h4>
      </span>
    </div>
  );
}

export default Step;
