import React, { useState, useEffect } from "react";

import Input from "../../../../components/Form/Controls/Input";
import Button from "../../../../components/Buttons/Buttons";
import SingleCheckBox from "../../../../components/Form/Controls/SingleCheckBox";
import CustomInput from "../../../../components/Form/Controls/CustomInput";
import Select from "../../../../components/Form/Controls/Select";
import VerificationSMS from "../../../../components/Form/Controls/VerficationSMS";
import FormContainer from "../../../../components/Form/FormContainer";
import Modal from "../../../../components/Modals/Modals";

import { EMAIL_VERIFICATION } from "../../../../constants/Regex";

import {
  findByDocumentNumber,
  requestCode,
} from "../../../../services/docSubmission";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";

function DatosRemitente({
  input,
  formData,
  setFormData,
  prev_correo,
  errors,
  setErrors,
}) {
  /*--------------DEFINICION DE CONSTANTES-----------------*/
  // console.log(errors);
  /* Valores disponibles para el SELECT */
  const values = [
    { index: 0, value: "", label: "Seleccione" },
    { index: 1, value: "dni", label: "DNI" },
    { index: 2, value: "ruc", label: "RUC" },
    { index: 3, value: "sin_ruc", label: "Sin RUC" },
  ];
  const [verifiedMail, setVerifiedMail] = useState(false);
  const [readOnly, isReadOnly] = useState(
    formData.numero.length > 0 || formData.tipo_doc === "sin_ruc"
  );
  const [nombreApi, setNombreApi] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useUpdateEffect(() => {
    setFormData({ ...formData, numero: "", nombre: "" });
    if (formData.tipo_doc === "") {
      isReadOnly(false);
    } else if (formData.tipo_doc === "sin_ruc") isReadOnly(false);
    else if (nombreApi.length > 0) isReadOnly(false);
    else isReadOnly(true);
  }, [formData.tipo_doc]);

  /*-----------------FUNCIONES CONSTANTES-------------------*/
  const validateMail = () => {
    if (formData.correo) {
      setVerifiedMail(true);
      requestCode(formData.correo);
      setOpenModal(true);
      setErrors({ ...errors, correo: false });
    } else {
      setVerifiedMail(false);
      setErrors({ ...errors, correo: true });
    }
  };

  useUpdateEffect(() => {
    if (
      (formData.tipo_doc === "dni" &&
        formData.numero.toString().length === 8) ||
      (formData.tipo_doc === "ruc" && formData.numero.toString().length === 11)
    ) {
      findByDocumentNumber(formData.numero).then((data) => {
        if (data.resultCode === 0) {
          isReadOnly(true);
          setNombreApi(data.data.value);
          setFormData({ ...formData, nombre: data.data.value });
        } else {
          isReadOnly(false);
          setNombreApi("");
          setFormData({ ...formData, nombre: "" });
        }
      });
    } else isReadOnly(false);
  }, [formData.numero]);

  return (
    <div className="datos-remitente">
      <FormContainer title="Datos Generales">
        <div className="label" style={{ textAlign: 'justify', color: '#042352' }}>
          Los datos que deben consignarse en el presente apartado son los datos de las partes, árbitros, adjudicadores o conciliadores decisorios que presentan el escrito y no los datos personales de los abogados o representantes de las mismas.
        </div><br></br>
        <div
          className={`datos-generales ${formData.tipo_doc === "sin_ruc" || !readOnly
            ? "datos-generales-cons"
            : ""
            }`}
        >
          <div className="select-documento">
            <Select
              onChange={(e) => {
                setFormData({ ...formData, tipo_doc: e.target.value });
              }}
              options={values}
              selection={formData.tipo_doc}
              errors={errors.tipo_doc}
              {...input[0]}
            />
          </div>
          {formData.tipo_doc !== "" && (
            <>
              <div className="input-numero">
                {formData.tipo_doc !== "sin_ruc" && (
                  <Input
                    value={formData.numero}
                    override="input-numero"
                    onChange={(e) => {
                      setFormData({ ...formData, numero: e.target.value });
                    }}
                    maxLength={
                      (formData.tipo_doc === "dni" && 8) ||
                      (formData.tipo_doc === "ruc" && 11)
                    }
                    minLength={
                      (formData.tipo_doc === "dni" && 8) ||
                      (formData.tipo_doc === "ruc" && 11)
                    }
                    feedback={
                      (formData.tipo_doc === "dni" &&
                        "Ingrese un número de DNI válido") ||
                      (formData.tipo_doc === "ruc" &&
                        "Ingrese un número de RUC válido")
                    }
                    errors={errors.numero}
                    {...input[1]}
                  />
                )}
              </div>
              <div className="input-nombre">
                <Input
                  value={formData.nombre}
                  override="input-numero"
                  label={
                    (formData.tipo_doc === "dni" && "Nombres y Apellidos:") ||
                    (formData.tipo_doc === "ruc" && "Razón Social:") ||
                    (formData.tipo_doc === "sin_ruc" && "Nombre del Consorcio:")
                  }
                  readOnly={readOnly}
                  onChange={(e) => {
                    setFormData({ ...formData, nombre: e.target.value });
                  }}
                  errors={errors.nombre}
                  {...input[2]}
                />
              </div>
              {formData.tipo_doc !== "sin_ruc" && readOnly && (
                <div className="input-modification">
                  <Button
                    onButtonClick={() => isReadOnly(false)}
                    override="override-btn-link-modify"
                    title={
                      <p className="text-secondary">
                        {`Modificar ${(formData.tipo_doc === "dni" && "el Nombre") ||
                          (formData.tipo_doc === "ruc" && "la Razón Social")
                          }`}
                      </p>
                    }
                    type="btn-link"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </FormContainer>
      <FormContainer title="Datos de Contacto">
        <div className="datos-contacto">
          <div className="input-celular">
            <Input
              value={formData.celular}
              override="input-celular"
              onChange={(value, country, e, formattedValue) => {
                setFormData({ ...formData, celular: formattedValue });
              }}
              {...input[3]}
            />
          </div>
          <div className="input-telefono">
            <div className="input-telephone">
              <Input
                value={formData.telefono}
                override="input-telephone"
                onChange={(value, country, e, formattedValue) => {
                  setFormData({ ...formData, telefono: formattedValue });
                }}
                {...input[4]}
              />
            </div>
            <div className="input-anexo">
              <Input
                value={formData.anexo}
                override="input-anexo"
                onChange={(e) => {
                  setFormData({ ...formData, anexo: e.target.value });
                }}
                {...input[5]}
              />
            </div>
          </div>
          <div className="input-email">
            <CustomInput
              value={formData.correo}
              override="input-numero"
              helper={
                <div className="email-helper">
                  <p className="text-dark text-justify">
                    {" "}
                    A este correo se remitirá el código de verificación
                    necesario para la presentación de su documento
                  </p>
                  {!verifiedMail && prev_correo !== formData.correo && (
                    <div className="email-button">
                      <Button
                        onButtonClick={() => validateMail()}
                        title={
                          <p className="text-secondary"> Enviar código </p>
                        }
                        type="btn-link"
                      />
                    </div>
                  )}
                </div>
              }
              onChange={(e) => {
                setFormData({ ...formData, correo: e.target.value });
              }}
              errors={errors.correo}
              {...input[6]}
            />
          </div>
          <div className="input-sms">
            <VerificationSMS
              onChange={(e) => {
                setFormData({ ...formData, emailValidacion: e.target.value });
              }}
              disabled={!verifiedMail}
              email={formData.correo}
              errors={errors.emailValidacion}
              openModal={openModal}
              setOpenModal={setOpenModal}
              {...input[7]}
            />
          </div>
        </div>
      </FormContainer>
      <div className="validacion">
        <SingleCheckBox
          onChange={(e) => {
            setFormData({ ...formData, validacion: !formData.validacion });
            setErrors({ ...errors, validacion: false });
          }}
          checked={formData.validacion}
          errors={errors.validacion}
          {...input[8]}
        />
      </div>
      {/*------------MANEJO DE MODALES PARA LA SOBRECARGA DE BOTONES------------*/}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Código"
        size="md"
        body="En unos minutos se enviará el código de verificación a la dirección de correo electrónico indicado. Revise su bandeja de correos o en su defecto la carpeta de no deseados o spam de ser el caso."
        footer={["Cerrar"]}
      />
    </div>
  );
}

export default DatosRemitente;
