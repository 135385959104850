import React from "react";
import "./Form.scss";

/* Componente que maneja el conteneder de un form a utilizar -> Para agrupar un conjunto de subformularios*/
function FormContainer({ title, children }) {
  return (
    <fieldset className="subform-fieldset">
      {title && (
        <legend>
          <h4 className="text-dark">{title}</h4>
        </legend>
      )}
      <div className="fieldset-wrapper">{children}</div>
    </fieldset>
  );
}

export default FormContainer;
