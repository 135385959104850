import React from 'react';
import './NavBar.scss';


//Barra de navegación que estará presente en todas las pantallas
//position-absolute top-0 start-50 translate-middle
function NavBar (){
  return(
    <div className = "navbar-component">
      <nav className="bg-primary navbar-main">
        <div className="navbar-container align-items-stretch">
          <a className="navbar-brand navbar-logo" href="/mesa_partes/documentos">
              <img src="/assets/CARC-06.svg" className="d-inline-block align-text-top" alt="ANÁLISIS Y RESOLUCIÓN DE CONFLICTOS PUCP"/>
          </a>
        </div>
      </nav>
    </div>
  )
}

export default NavBar;