import { bottom } from "@popperjs/core";
import React, { useState, useEffect } from "react";
import FormContainer from "../../../../components/Form/FormContainer";
import { downloadDocument } from "../../../../services/docSubmission";

import FileDownload from "js-file-download";

function convertToCode(codigo) {
  const modificado = codigo.split("_");
  if (modificado.length < 2) return modificado;
  else return modificado[0] + "-" + modificado[1].toString().padStart(4, "0");
}

function Cargo({ dataFinal, setError }) {
  const [principal, setPrincipal] = useState([]);
  const [anexos, setAnexos] = useState([]);
  useEffect(() => {
    const auxPrincipal = [];
    const auxAnexos = [];

    for (const file of dataFinal.filesMetadata) {
      if (file.mainFile) auxPrincipal.push(file);
      else auxAnexos.push(file);
    }

    setPrincipal(auxPrincipal);
    setAnexos(auxAnexos);
  }, []);

  const handleClick = async (value, name) => {
    let tempLabel = "", tempAlert = "";
    const data = await downloadDocument(value);
    if (!data.resultCode) {
      /* SE PROCEDE AL DESCARGO */
      FileDownload(data, name);
    }else{
      tempLabel = "Ocurrió un error al descargar el archivo, intente nuevamente.";
    }
    setError(tempLabel);
  };

  return (
    <div className="cargo">
      {dataFinal.senderData !== undefined && (
        <FormContainer>
          <div className="resumen">
            <label className="form-label text-primary">
              <h4>{`REGISTRO N° ${convertToCode(
                dataFinal.statementCode
              )} DE MESA DE PARTES VIRTUAL - CENTRO DE ANÁLISIS Y RESOLUCIÓN DE CONFLICTOS - PUCP`}</h4>
            </label>
            <h5 className="text-dark">{`Formulario registrado el ${dataFinal.submissionDate} a las ${dataFinal.submissionHour} horas.`}</h5>
          </div>
          <div className="resumen-datos">
            <label className="form-label text-primary">
              <h4>{`Datos del Remitente:`}</h4>
            </label>
            <div className="numero-doc">
              <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                <b>
                  <i>{`${
                    (dataFinal.senderData.documentType === 1 && "DNI:") ||
                    (dataFinal.senderData.documentType === 2 && "RUC:") ||
                    (dataFinal.senderData.documentType === 3 && "Sin RUC.")
                  }`}</i>
                </b>
              </h5>
              <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                {`${
                  dataFinal.senderData.documentType !== 3 &&
                  dataFinal.senderData.documentNumber
                }`}
              </h5>
            </div>
            <div className="nombre">
              <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                <b>
                  <i>{`${
                    (dataFinal.senderData.documentType === 1 &&
                      "Nombre y Apellido:") ||
                    (dataFinal.senderData.documentType === 2 &&
                      "Razon Social:") ||
                    (dataFinal.senderData.documentType === 3 &&
                      "Nombre del Consorcio:")
                  }`}</i>
                </b>
              </h5>
              <h5
                className="text-dark"
                style={{ maxWidth: "inherit", wordWrap: "break-word" }}
              >
                {dataFinal.senderData.fullName}
              </h5>
            </div>
            <div className="datos-contacto">
              <div className="celular">
                <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                  <b>
                    <i>Celular:</i>
                  </b>
                </h5>
                <h5 className="text-dark" style={{ width: "inherit" }}>
                  {`${
                    dataFinal.senderData.cellPhone !== null &&
                    dataFinal.senderData.cellPhone !== ""
                      ? dataFinal.senderData.cellPhone
                      : "-"
                  }`}
                </h5>
              </div>
              <div className="telefono">
                <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                  <b>
                    <i>, Teléfono:</i>
                  </b>
                </h5>
                <h5 className="text-dark" style={{ width: "inherit" }}>
                  {`${
                    dataFinal.senderData.phoneNumber !== null &&
                    dataFinal.senderData.phoneNumber !== ""
                      ? dataFinal.senderData.phoneNumber
                      : "-"
                  }`}
                </h5>
              </div>
              <div className="anexo">
                <h5 className="text-dark" style={{ width: "inherit" }}>
                  <b>
                    <i>- Anexo:</i>
                  </b>
                </h5>
                <h5 className="text-dark" style={{ width: "inherit" }}>
                  {`${
                    dataFinal.senderData.phoneExtension !== null &&
                    dataFinal.senderData.phoneExtension !== ""
                      ? dataFinal.senderData.phoneExtension
                      : "-"
                  }`}
                </h5>
              </div>
            </div>
            <div className="correo-electronico">
              <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                <b>
                  <i>Correo electrónico:</i>
                </b>
              </h5>
              <h5 className="text-dark">{dataFinal.senderData.email}</h5>
            </div>
            <div className="nro-expediente">
              {dataFinal.documentData.fileNumber !== "" &&
              dataFinal.documentData.fileNumber !== null ? (
                <>
                  <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                    <b>
                      <i>Nro. Expediente:</i>
                    </b>
                  </h5>
                  <h5 className="text-dark">
                    {dataFinal.documentData.fileNumber}
                  </h5>
                </>
              ) : (
                <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
                  <b>
                    <i>Sin Número de Expediente</i>
                  </b>
                </h5>
              )}
            </div>
          </div>
          <div className="resumen-asunto">
            <label className="form-label text-primary">
              <h4>{`Asunto:`}</h4>
            </label>
            <h5 className="text-dark" style={{ maxWidth: "inherit" }}>
              <i>{dataFinal.documentData.subject}</i>
            </h5>
          </div>
          {principal.length > 0 &&
            (dataFinal.filesMetadata.length <= 1 ||
              (dataFinal.filesMetadata.length > 1 && anexos.length > 0)) && (
              <div className="resumen-documentos">
                <label className="form-label text-primary">
                  <h4>{`Documento Principal:`}</h4>
                </label>
                <button
                  style={{ padding: "0rem" }}
                  type="button"
                  className="btn btn-link"
                  onClick={() =>
                    handleClick(
                      principal[0].hashDocument,
                      principal[0].fileName
                    )
                  }
                >
                  <h5 className="text-dark text-start">
                    {"-"}
                    <u>{`${principal[0].fileName}`}</u>{" "}
                    {`(${principal[0].bytesSizeText})`}
                  </h5>
                </button>
                {anexos.length > 0 && (
                  <>
                    <label className="form-label text-primary">
                      <h4>{`Documento(s) Anexo(s):`}</h4>
                    </label>
                    {anexos.map((file, index) => (
                      <button
                        style={{ padding: "0rem", marginBottom: "1rem" }}
                        key={index}
                        type="button"
                        className="btn btn-link"
                        onClick={() =>
                          handleClick(file.hashDocument, file.fileName)
                        }
                      >
                        <h5 className="text-dark text-start">
                          {"-"}
                          <u>{`${file.fileName}`}</u>{" "}
                          {`(${file.bytesSizeText})`}
                        </h5>
                      </button>
                    ))}
                  </>
                )}
              </div>
            )}
          <h5 className="text-dark">
            <b>
              <u>NOTA:</u>
            </b>
            {"    "}
            En un plazo máximo de un día hábil podrá hacer seguimiento a su
            escrito a través del portal del Sistema de Gestión Arbitral del
            Centro de Análisis y Resolución de Conflictos - PUCP (
            <a
              className="text-secondary"
              href=" https://sigeapucp.com/gestionarbitraje/pages/public/login"
            >
              SIGEA
            </a>
            ) opción “Consulta de expedientes”. Revise su bandeja de correos o
            en su defecto la carpeta de no deseados o spam de ser el caso.
          </h5>
        </FormContainer>
      )}
    </div>
  );
}

export default Cargo;
