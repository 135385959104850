import React from "react";

import Cards from "../../components/Cards/Cards.js";
import Title from "../../components/Title/Title.js";

import "./Home.scss";

const Home = () => {
  const cards = [
    {
      index: 1,
      title: "Mesa de partes virtual",
      body: "Ingresa aquí si deseas presentar solicitudes, contestaciones, escritos y documentos en general.",
      height: "min",
      route: "/mesa_partes",
      icon: "/assets/icons/Cloud.svg",
    },
    {
      index: 2,
      title: "Consulta de expedientes",
      body: "Consulta el estado y avance de un arbitraje, una conciliación decisoria y un árbrito de emergencia, para lo cual necesitará conocer el número de expediente que corresponda.",
      height: "min",
      route: "/consulta_expedientes",
      icon: "/assets/icons/Stack_of_Paper.svg",
    },
  ];
  return (
    <div className="main-background">
      <div className="circles">
        <div className="circles-circle"></div>
        <div className="circles-circle"></div>
        <div className="circles-circle"></div>
      </div>
      <Title
        title="Sistema de Gestión Arbitral"
        subtitle="Menu Principal"
        textColor="text-white"
      ></Title>
      {/* <div>
        <img src="/assets/main-background.svg" className="d-inline-block align-text-top" alt="ANÁLISIS Y RESOLUCIÓN DE CONFLICTOS PUCP"/>
      </div> */}
      <Cards cards={cards} height={"min"}></Cards>
    </div>
  );
};

export default Home;
