import React, { useState, useEffect, useRef } from "react";
import "../Form.scss";

import Button from "../../Buttons/Buttons";

import { requestCode } from "../../../services/docSubmission";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import { GB_1 } from "../../../constants/Dummy";
import List from "../../Lists/List";

import { uploadDocument } from "../../../services/docSubmission";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

import prettyBytes from "pretty-bytes";

const submitFiles = async (
  files,
  docNumber,
  main,
  typeFiles,
  setTypeFiles,
  isUploading
) => {
  /* FUNCION PARA LA SUBIDA DEL DOCUMENTO CORRESPONDIENTE */
  let data,
    tempLabel = "",
    tempAlert = "";
  const auxFiles = [];

  for (let i = 0; i < files.length; i++) {
    let fileFormatted = {
      name: files[i].name,
      size: files[i].size,
      type: files[i].type,
      mainFile: files[i].mainFile,
    };

    await isUploading(files[i].name);

    data = await uploadDocument(files[i], docNumber, main);
    console.log(fileFormatted);
    if (data.resultCode === 0) {
      fileFormatted.value = data.data.value;
      auxFiles.push(fileFormatted);
      await setTypeFiles((prevFiles) => {
        const newFiles = [...prevFiles, fileFormatted];
        return newFiles;
      });
    } else if (data.message.resultCode === 1002) {
      tempAlert = "alert-warning";
      tempLabel = `El nombre del archivo "${fileFormatted.name}" supera la cantidad máxima de caracteres (30)`;
    } else {
      tempAlert = "alert-warning";
      tempLabel = "Ocurrió un error al subir el archivo, intente nuevamente.";
    }
    isUploading(null);
  }

  return { auxFiles, tempAlert, tempLabel };
};

/* Componente reutilizable con nombre genérico
  -> VerificationSMS sirve para poder emitir un código de verificación en SMS */
function FileSubmission(props) {
  /*
    type -> Si es principal o no{
      principal -> es el principal y solo admite un documento
      secundario -> admite una gran cantidad de documentos y no hay validación
    } 
    label -> se refiere al nombre del label que se manejará
    errors -> TRUE OR FALSE -> En caso se active un caso de error se cambia a rojo -> otro estado
    feedback -> feedback en caso haya un error
    docNumber -> es el codigo de subida del documento correspondiente
    ...other para otras propiedades que se puedan ir adquiriendo*/
  const {
    type,
    label,
    files,
    setFiles,
    saveFiles,
    errors = false,
    feedback,
    docNumber,
    isValid,
    setIsValid,
    setShow,
  } = props;

  const [typeFiles, setTypeFiles] = useState([]);
  const [uploading, isUploading] = useState(null);

  const inputElement = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleted, isDeleted] = useState(null);

  function handleSubmit(e) {
    inputElement.current.click();
  }

  function setError(message) {
    setIsValid({
      ...isValid,
      type: "alert-warning",
      label: message,
    });
  }

  //Traemos la data de la Base de Datos para los archivos

  async function handleInput(e) {
    const auxFiles = [...e.target.files];
    let auxArray = [];
    let tempAlert = "",
      tempLabel = "";

    setIsValid({
      ...isValid,
      type: "alert-loading",
      label: "Se están subiendo los archivos.",
    });
    setShow(true);

    for (const file of auxFiles) {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      //Validación si el tamaño del arcihvo es superior a 1GB
      if (file.size > GB_1) {
        tempAlert = "alert-warning";
        tempLabel = "El tamaño del archivo no debe superar la capacidad máxima";
        setIsValid({
          ...isValid,
          type: tempAlert,
          label: tempLabel,
        });
        return;
      }

      //Validación por el número de caracteres de un archivo
      if (file.name.length > 30) {
        tempAlert = "alert-warning";
        tempLabel = `El nombre del archivo "${file.name}" supera la cantidad máxima de caracteres (30)`;
        setIsValid({
          ...isValid,
          type: tempAlert,
          label: tempLabel,
        });
        return;
      }

      if (type === "primary") {  //Validación si el archivo primary es de tipo .pdf
        if (file.type !== "application/pdf" && fileExtension !== "pdf") {
          tempAlert = "alert-warning";
          tempLabel = "El documento principal debe ser de tipo PDF";
          setIsValid({
            ...isValid,
            type: tempAlert,
            label: tempLabel,
          });
          return;
        }
      } else { //Validación si los archivos anexos son de los tipos permitidos
        const allowedFileTypes = [
          { extension: "pdf", mime: "application/pdf" },
          { extension: "doc", mime: "application/msword" },
          { extension: "docx", mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
          { extension: "xls", mime: "application/vnd.ms-excel" },
          { extension: "xlsx", mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
          { extension: "jpg", mime: "image/jpeg" },
          { extension: "jpeg", mime: "image/jpeg" },
          { extension: "mp3", mime: "audio/mpeg" },
          { extension: "mp4", mime: "video/mp4" },
          { extension: "zip", mime: "application/zip" },
          { extension: "rar", mime: "application/vnd.rar" },
          { extension: "7z", mime: "application/x-7z-compressed" },
          { extension: "dbf", mime: "application/octet-stream" }
        ];

        const isValidFile = allowedFileTypes.some(type => type.extension === fileExtension && type.mime === file.type);

        if (!isValidFile) {
          tempAlert = "alert-warning";
          tempLabel = "Los documentos anexos deben ser de tipo PDF, DOC, DOCX, XLS, XLSX, JPG, JPEG, MP3, MP4, ZIP, RAR o 7Z";
          setIsValid({
            ...isValid,
            type: tempAlert,
            label: tempLabel,
          });
          return;
        }
      }

      auxArray.push(file);
    }

    if (auxArray.length === 0) {
      tempAlert = "alert-warning";
      tempLabel = "No se seleccionó ningún archivo válido.";
    }

    let otherGroupFiles = type === "primary" ? files.documentos_anexos : files.documento_principal;

    // Validación para evitar duplicados entre archivos principales y anexos
    for (let auxFile of auxFiles) {
      for (let otherFile of otherGroupFiles) {
        if (auxFile.name === otherFile.name) {
          auxArray.splice(auxArray.findIndex(file => file.name === auxFile.name), 1);
          tempAlert = "alert-warning";
          tempLabel = "El archivo '" + auxFile.name + "' ya ha sido subido en la otra sección (principal o anexos).";
          setIsValid({
            ...isValid,
            type: tempAlert,
            label: tempLabel,
          });
        }

      }
    }

    if (typeFiles.length >= 0) {
      //Validación de que no se contengan el mismo archivo
      for (let i = 0; i < auxFiles.length; i++) {
        for (let j = 0; j < typeFiles.length; j++) {
          if (typeFiles[j].name === auxFiles[i].name) {
            auxArray.splice(
              auxArray.findIndex((file) => file.name === auxFiles[i].name),
              1
            );
            tempAlert = "alert-warning";
            tempLabel = "Algunos de los documentos no se lograron subir porque ya fueron previamente guardados.";
          }
        }
      }
    }
    //Subida del archivo a la Base de Datos
    if (auxArray.length > 0) {
      submitFiles(
        auxArray,
        docNumber,
        type === "primary" ? 1 : 0,
        typeFiles,
        setTypeFiles,
        isUploading
      ).then((auxFiles) => {
        tempAlert = auxFiles.tempAlert !== "" ? auxFiles.tempAlert : tempAlert;
        tempLabel = auxFiles.tempLabel !== "" ? auxFiles.tempLabel : tempLabel;
        auxArray = typeFiles.concat(auxFiles.auxFiles);
        saveFiles(auxArray);
        if (tempAlert !== "") {
          setIsValid({
            ...isValid,
            type: tempAlert,
            label: tempLabel,
          });
        } else {
          setIsValid({
            ...isValid,
            type: "alert-success",
            label: "Se subieron todos los archivos correctamente",
          });
        }
      });
    } else {
      setIsValid({
        ...isValid,
        type: tempAlert,
        label: tempLabel,
      });
    }
    if (inputElement.current) {
      inputElement.current.value = null;
    }

  }

  useEffect(() => {
    const auxFiles = [];
    if (type === "primary") {
      if (files.documento_principal.length > 0)
        auxFiles.push(files.documento_principal[0]);
    } else {
      for (let file of files.documentos_anexos) auxFiles.push(file);
    }

    setTypeFiles(auxFiles);
  }, []);

  useUpdateEffect(() => {
    const auxArray = [...typeFiles];
    auxArray.splice(
      auxArray.findIndex((file) => file.value === deleted),
      1
    );
    setTypeFiles(auxArray);
    saveFiles(auxArray);
    if (inputElement.current) {
      inputElement.current.value = null;
    }
  }, [deleted]);
  return (
    <div className={`file-submission `}>
      <div className="files-label">
        {label && (
          <label className="form-label text-primary">
            <h4 style={{ marginBottom: "0px" }}>{label}</h4>
          </label>
        )}
      </div>
      {!uploading && ((type === "primary" && typeFiles.length <= 0) ||
        type !== "primary") && (
          <div className="files-button">
            <input
              type="file"
              id={`${type}-fileupload`}
              ref={inputElement}
              style={{ display: "none" }}
              accept={type === "primary" ? ".pdf" : "*"}
              multiple={false}
              onInput={handleInput}
            />
            <Button
              type="btn-outline-primary"
              override="override-btn-outline-primary"
              id={`${type}-OpenFileUpload`}
              title={
                <div className="button-components">
                  <h4 style={{ marginBottom: "4px" }}>Añadir</h4>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
              }
              onButtonClick={handleSubmit}
            />
          </div>
        )}
      <div className="files-list">
        {typeFiles.length <= 0 ? (
          <p className="text-secondary">
            <u>Aún no se han subido archivos</u>
          </p>
        ) : (
          <List
            listItem="file-item"
            list={typeFiles}
            isDeleted={isDeleted}
            openModal={openModal}
            setOpenModal={setOpenModal}
            toDelete={true}
            toDownload={true}
            isUploaded={uploading}
            setError={setError}
          />
        )}
      </div>
    </div>
  );
}

export default FileSubmission;
