import React, { useState } from "react";

import "../Form.scss";
/* Componente reutilizable con nombre genérico
  -> TEXTAREA PARA EL MANEJO DE FORMS */
function TextArea(props) {
  /*  id -> IDENTIFICADOR UNICO PARA EL FORM
      value -> Importante para el manejo de carga de datos inicial (Fetch)
      height -> El tamaño que obtendrá el textArea
      label -> se refiere al nombre del label que se manejará
      helper -> definido como un apoyo debajo de la casilla del input
      onChange -> para evaluar el cambio cosntante por eventos
      feedback -> feedback en caso haya un error
      ...textProps para otras propiedades que se puedan ir adquiriendo 
        -> {PLACEHOLDER, VALUE, REQUIRED, DISABLED, PATTERN, ETC...}*/
  const {
    id,
    value,
    height,
    label,
    helper,
    feedback,
    onChange,
    errors = false,
    ...textProps
  } = props;

  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };
  const [count, setCount] = React.useState(value.length);

  const handleInput = (e) => {
    setCount(e.target.value.length);
  };

  return (
    <div className="text-container-area">
      {label && (
        <label className="form-label text-primary">
          <h4>{label}</h4>
        </label>
      )}
      <textarea
        id={id}
        value={value}
        className={`form-control text-primary ${"border-primary"}`}
        style={{ height: `${height}` }}
        onChange={onChange}
        onBlur={handleFocus}
        onInput={handleInput}
        focused={!errors ? focused.toString() : "true"}
        {...textProps}
      />
      {helper && (
        <div
          id={`helper_${id}`}
          className={`form-text text-end ${feedback && "valid-div"}`}
        >
          <p className="text-dark">{` ${count} de ${helper}`}</p>
        </div>
      )}
      {feedback && (
        <div
          id={`helperror_${id}`}
          className="form-text text-start invalid-feedback invalid-div"
        >
          <p className="text-danger">{feedback}</p>
        </div>
      )}
      {!helper && (
        <div
          id={`none_${id}`}
          className={`margin-bottom ${feedback && "valid-div"}`}
        ></div>
      )}
    </div>
  );
}

export default TextArea;
