import React, { useState, useEffect } from "react";
import "../Form.scss";

/* Componente reutilizable con nombre genérico
  -> SINGLE CHECKBOX para el manejo de BOXES NECESARIOS PARA MARCAR */
function SingleCheckBox(props) {
  /* id -> IDENTIFICADOR UNICO PARA EL FORM
    label -> se refiere al nombre del label que se manejará -> es de forma compleja para estilizar el checkbox
    helper -> definido como un apoyo debajo de la casilla del input  
    onChange -> para evaluar el cambio cosntante por eventos
    required -> Para futuras validaciones, si es un campo obligatorio
    disabled -> Por defecto en falso | maneja estados de activación
    errors -> TRUE OR FALSE -> En caso se active un caso de error se cambia a rojo -> otro estado
    feedback -> feedback en caso haya un error
    ...other para otras propiedades que se puedan ir adquiriendo*/
  const {
    id,
    label,
    value = false,
    helper,
    onChange,
    errors = false,
    feedback,
    ...checkProps
  } = props;

  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <>
      <div className="checkbox-text">
        <div className="checkbox-text-checkbox">
          <input
            className={`form-check-input border-primary checkbox`}
            type="checkbox"
            id={id}
            value={value}
            onChange={onChange}
            onBlur={handleFocus}
            focused={!errors ? focused.toString() : "true"}
            // style={{backgroundColor: 'white'}}
            {...checkProps}
          />
        </div>
        <div className="checkbox-text-text">
          <label className="form-check-label">{label}</label>
        </div>
      </div>
      <div className="helper-text">
        {helper && !(feedback && errors) && (
          <div
            id={`helper_${id}`}
            className={`form-text ${feedback && "valid-div"}`}
          >
            <p className="text-dark">{helper}</p>
          </div>
        )}
        {feedback && errors && (
          <div id={`helperror_${id}`} className="form-text">
            <p className="text-danger">{feedback}</p>
          </div>
        )}
        {!(helper && feedback && errors) && (
          <div
            id={`none_${id}`}
            className={`margin-bottom ${feedback && "valid-div"}`}
          ></div>
        )}
      </div>
    </>
  );
}

export default SingleCheckBox;
