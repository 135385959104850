import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";

import "../Form.scss";
import "react-phone-input-2/lib/style.css";
/* Componente reutilizable con nombre genérico
  -> INPUT TEXT para el manejo de FORMS */
function CustomInput(props) {
  /*  id -> IDENTIFICADOR UNICO PARA EL FORM
      type -> el tipo del input -> {text, email, textArea, password, etc...}
      color -> el color correspondiente al texto que será llenado
      label -> se refiere al nombre del label que se manejará
      helper -> definido como un apoyo debajo de la casilla del input
      onChange -> para evaluar el cambio cosntante por eventos
      feedback -> feedback en caso haya un error
      ...inputProps para otras propiedades que se puedan ir adquiriendo 
        -> {PLACEHOLDER, VALUE, REQUIRED, DISABLED, PATTERN, ETC...}*/
  const {
    id,
    type = "text",
    value,
    label,
    color,
    helper,
    feedback,
    errors = false,
    override,
    onChange,
    ...inputProps
  } = props;

  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  const handleInput = (evt) => {
    if (type === "number") {
      var theEvent = evt || window.evevtent;

      // Handle paste
      if (theEvent.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
      } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };
  return (
    <>
      {label && <label className="form-label text-primary">{label}</label>}
      {type === "tel" ? (
        <PhoneInput
          id={id}
          value={value}
          className={`border-primary ${!color ? "text-primary" : color}`}
          country={"pe"}
          onChange={onChange}
          onBlur={handleFocus}
          focused={!errors ? focused.toString() : "true"}
          {...inputProps}
        />
      ) : (
        <input
          id={id}
          value={value}
          type={type} /* {text, email, textArea, password, etc...} */
          className={`form-control border-primary override-${override} ${
            !color ? "text-primary" : color 
          }`}
          style={{textAlign: 'center'}}
          onChange={onChange}
          onKeyPress={handleInput}
          onBlur={handleFocus}
          focused={!errors ? focused.toString() : "true"}
          {...inputProps}
        />
      )}
      {helper && (
        <div
          id={`helper_${id}`}
          className={`form-text ${feedback && "valid-div"}`}
        >
          {helper}
        </div>
      )}
      {feedback && (
        <div
          id={`helperror_${id}`}
          className="form-text invalid-div invalid-feedback"
        >
          <p className="text-danger">{feedback}</p>
        </div>
      )}
      {!helper && (
        <div
          id={`none_${id}`}
          className={`margin-bottom ${feedback && "valid-div"}`}
        ></div>
      )}
    </>
  );
}

export default CustomInput;
